import { getApi } from "@/assets/ts/stage";

export class Set {
  get(
    country: string,
    language: string,
    setId: string,
    metal: string,
    quality: string
  ) {
    return new Promise((resolve, reject) => {
      if (metal === "-") metal = "";

      if (quality === "-") quality = "";

      const url =
        getApi() +
        "/public/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/set/" +
        setId.toLowerCase() +
        "/" +
        metal.toLowerCase() +
        "/" +
        quality.toLowerCase();

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  getPrice(
    token: string,
    country: string,
    setId: string,
    metal: string,
    quality: string
  ) {
    return new Promise((resolve, reject) => {
      if (metal === "-") metal = "";

      if (quality === "-") quality = "";

      const url =
        getApi() +
        "/store/item/" +
        setId.toLowerCase() +
        "/" +
        metal.toLowerCase() +
        "/" +
        quality.toLowerCase() +
        "/price/" +
        country.toLowerCase();

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
