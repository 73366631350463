import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./assets/tailwind.css";

import "./index.css";
import "./assets/tailwind.css";

const euroNumbers = {
  currency: {
    style: "currency",
    currency: "EUR",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const chfNumbers = {
  currency: {
    style: "currency",
    currency: "CHF",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const gelNumbers = {
  currency: {
    style: "currency",
    currency: "GEL",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const amdNumbers = {
  currency: {
    style: "currency",
    currency: "AMD",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const leuNumbers = {
  currency: {
    style: "currency",
    currency: "LEU",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const ukNumbers = {
  currency: {
    style: "currency",
    currency: "GBP",
  },
  credits: {
    style: "decimal",
    minimumFractionDigits: 2,
  },
  carat: {
    style: "decimal",
    minimumFractionDigits: 3,
  },
};

const i18n = createI18n({
  legacy: false,
  defaultLocale: "de-DE",
  fallbackLocale: "en-DE",
  messages: {
    "de-DE": require("./locales/de.json"),
    "de-CH": require("./locales/de.json"),
    "de-AT": require("./locales/de.json"),

    "en-DE": require("./locales/en.json"),
    "en-CH": require("./locales/en.json"),
    "en-AT": require("./locales/en.json"),

    "fr-DE": require("./locales/fr.json"),
    "fr-CH": require("./locales/fr.json"),
    "fr-AT": require("./locales/fr.json"),
  },
  numberFormats: {
    "de-DE": euroNumbers,
    "de-CH": chfNumbers,
    "de-AT": euroNumbers,

    "en-DE": euroNumbers,
    "en-CH": chfNumbers,
    "en-AT": euroNumbers,

    "fr-DE": euroNumbers,
    "fr-CH": chfNumbers,
    "fr-AT": euroNumbers,
  },
});

createApp(App).use(store).use(router).use(i18n).mount("#myringhub-plugin");
