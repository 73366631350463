import { getApi } from "@/assets/ts/stage";

export class Items {
  get(country: string, language: string, items: string[]) {
    return new Promise((resolve, reject) => {
      const url =
        getApi() +
        "/public/" +
        country.toLowerCase() +
        "/" +
        language.toLowerCase() +
        "/item/" +
        items.join("/");

      fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  }
}
